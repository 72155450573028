import PropTypes from 'prop-types';
import React from 'react';

import styles from './stackList.module.css';

const Stack = ({ children, title, className }) => (
  <div className={[styles.stackItem, className].join(' ')}>
    <span className={styles.title}>{title}</span>
    {children}
  </div>
)

const TextLink = ({children, url}) => <a href={url} target="_blank" rel="noopener noreferrer">{children}</a>

const Stacklist = ({ className }) => {
  return (
   <div className={styles.stack}>
    <div className={styles.col}>
      <Stack className={styles.html} title="HTML5">Responsive</Stack>
      <Stack className={styles.css} title="CSS">native, PostCSS, Sass</Stack>
      <Stack className={styles.js} title="JavaScript">
        native ES6,
        React.js,
        <TextLink url="https://www.gatsbyjs.org"> Gatsby.js</TextLink>,
        <TextLink url="https://nextjs.org"> NEXT.js</TextLink>,
        node, GraphQL</Stack>
    </div>
    <div className={styles.col}>
      <Stack className={styles.cms} title="CMS">Neos CMS, Prismic CMS</Stack>
      <Stack className={styles.serverless} title="Serverless">
        <TextLink url="https://www.algolia.com"> algolia</TextLink>,
        (<TextLink url="https://www.fauna.com"> FaunaDB</TextLink>),
        <TextLink url="https://www.vercel.com"> Vercel Now</TextLink>,
        <TextLink url="https://www.digitalocean.com"> DigitalOcean</TextLink> ...</Stack>
      <Stack className={styles.tools} title="Tools">Git, VS Code, Figma, Docker, Terraform</Stack>
    </div>
  </div>
  );
}

Stacklist.propTypes = {
  /** Additional classname for individual styling in the the parent component */
  className: PropTypes.string
}

Stacklist.defaultProps = {
  className: null
}

export default Stacklist;
