import React from "react";

import Layout   from '../components/layout';
import Seo      from "../components/atoms/seo/seo";
import StageWelcome from "../components/molecules/stageWelcome/stageWelcome";

export default () => (
  <>
    <Seo
      title="Welcome"
    />
    <Layout>
      <StageWelcome />
    </Layout>
  </>
)
