import PropTypes from "prop-types"
import React from "react"

import Stage from "../stage/stage"
import StackList from "../stackList/stackList"
import Headline from "../../atoms/headline/headline"
import Slugline from "../../atoms/headline/slugline"
import Text from "../../atoms/text/text"
import Button from "../../atoms/button/button"

import styles from "./stageWelcome.module.css"
import Person from "../../../images/person.svg"
import Arrow from "../../../images/arrow.svg"

const StageWelcome = ({ className }) => {
  return (
    <Stage
      className={styles.stage}
      alignment="left"
      alignVert="middle"
      fullHeight={true}
      styleFull="primary"
    >
      <Headline className={styles.headline}>Hi, ich bin Björn,</Headline>
      <Slugline className={styles.slackline}>
        ich entwickle Websites, Web-Apps und MVPs mit modernen Technologien.
      </Slugline>
      <div className={styles.arrowBox}>
        <Arrow />
        <Text className={styles.jobTitle} tag="div">
          Senior Fullstack Software Engineer
          <br />
          mit Fokus auf Frontend und Serverless Architekturen.
        </Text>
      </div>
      <div className={styles.content}>
        <div className={styles.col}>
          <Button className={styles.cta} href="/kontakt" tagName="a">
            contact me
          </Button>
          <StackList />
        </div>
        <div className={[styles.col, styles.imageCol].join(" ")}>
          <Person className={styles.person} />
        </div>
      </div>
    </Stage>
  )
}

StageWelcome.propTypes = {
  /** Additional classname for individual styling in the the parent component */
  className: PropTypes.string,
}

StageWelcome.defaultProps = {
  className: null,
}

export default StageWelcome
